<template>
  <div class="user-guide__page">
    <div class="user-guide__search">
      <div class="user-guide__search-background">
        <img src="@/assets/images/user-guide-background.png">
      </div>
      <div class="user-guide__header">
        Knowledge Center
      </div>
      <UserGuideSearch @emitedData="changeSearch" />
    </div>
    <div
      v-if="highlightedCards.length"
      class="user-guide__content"
    >
      <UserGuideItem
        v-for="item in highlightedCards"
        :key="item._id"
        :card="item"
      />
    </div>
    <UserGuideEmpty
      v-else
      :error="error"
      :search="search"
      @reload="reloadRequest"
    />
    <div class="user-guide__pagination">
      <UserGuidePagination @paginationChange="paginationChange" />
    </div>
    <transition name="loading-fade">
      <UserGuideLoading v-if="loading" />
    </transition>
  </div>
</template>

<script>
// vuex
import { mapGetters, mapActions, mapMutations } from 'vuex'

// components
import UserGuideLoading from './components/UserGuideLoading.vue'
import UserGuideSearch from './components/UserGuideSearch.vue'
import UserGuideItem from './components/UserGuideItem.vue'
import UserGuideEmpty from './components/UserGuideEmpty.vue'
import UserGuidePagination from './components/UserGuidePagination.vue'

export default {
  components: {
    UserGuideLoading,
    UserGuideSearch,
    UserGuideItem,
    UserGuideEmpty,
    UserGuidePagination,
  },
  data() {
    return {
      loading: false,
      search: '',
      error: false,
    }
  },
  computed: {
    ...mapGetters('userGuide', ['getUgCards', 'getUgPagination']),
    highlightedCards() {
      if (!this.search) return this.getUgCards
      const searchRegex = new RegExp(`(${this.search})`, 'gi')
      
      return this.getUgCards.map(card => {
        const highlightedValue = card.name.replace(
          searchRegex,
          '<span>$1</span>'
        )
        return {
          ...card,
          name: highlightedValue
        }
      })
    }
  },
  mounted() {
    this.loadUserGuides()
  },
  methods: {
    ...mapActions({
      getUserGuides: 'userGuide/getUserGuides',
    }),
    ...mapMutations('userGuide', ['SET_UG_CARDS', 'SET_UG_PAGINATION_TOTAL', 'SET_UG_PAGINATION_CURRENT_PAGE']),
    async loadUserGuides() {
      this.loading = true
      this.error = false 

      await this.getUserGuides({ 
        name: this.search, 
        page: this.getUgPagination.currentPage, 
        limit: this.getUgPagination.perPage 
      })
        .then(({ data }) => {
          this.SET_UG_CARDS(data.items)
          this.SET_UG_PAGINATION_TOTAL(data.totalPages)
        })
        .catch(e => {
          this.$toasted.global.error({ message: e })
          this.error = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    paginationChange() {
      this.loadUserGuides()
    },
    changeSearch({ search }) {
      this.search = search
      this.SET_UG_PAGINATION_CURRENT_PAGE(1)
      this.loadUserGuides()
    },
    reloadRequest() {
      this.loadUserGuides()
    }
  }
}
</script>

<style scoped>
.user-guide__page {
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}
.user-guide__header {
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
}

.user-guide__search {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 40px;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  border-bottom: none;
  border-radius: 16px 16px 0 0;
  position: relative;
}
.user-guide__search-background {
  width: 455px;
  aspect-ratio: 455 / 158;
  border-radius: 0 16px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
}
.user-guide__search-background img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.user-guide__search::v-deep .gl-search-box {
  gap: 16px;
  width: 656px;
}
.user-guide__search::v-deep .gl-search-box .gl-input__input {
  min-width: 450px !important;
  padding-left: 16px;
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 8px;
}
.user-guide__search::v-deep .gl-search-box .gl-button {
  width: 140px;
}

.user-guide__content {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  gap: 24px;
  padding: 48px 40px;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  overflow-y: auto;
}
.user-guide__content::-webkit-scrollbar {
  width: 6px;
}

.user-guide__pagination {
  padding: 16px 24px;
  background-color: var(--white);
  border: 1px solid var(--cotton-grey-f-5);
  border-top: none;
  border-radius: 0 0 16px 16px;
}
.pp-pagination-table {
  padding: 0;
}

@media screen and (max-width: 1439px) {
  .user-guide__content {
    grid-template-columns: repeat(2, 1fr);
    padding: 24px;
  }
  .user-guide__card[data-v-781a646c] {
    padding: 32px 24px;
  }
}
@media screen and (max-width: 1199px) {
  .user-guide__search {
    padding: 48px 24px;
  }
  .user-guide__search::v-deep .gl-search-box {
    width: 100%;
  }
  .user-guide__search-background {
    width: 300px;
  }
}
@media screen and (max-width: 767px) {
  .user-guide__page {
    padding: 54px 8px 0 8px;
  }
  .user-guide__search {
    align-items: start;
    margin: 0 -8px 8px -8px;
    padding: 16px;
    border-radius: 0;
  }
  .user-guide__header {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 26px;
  }
  .user-guide__search-wrap {
    width: 100%;
  }
  .user-guide__search::v-deep .gl-search-box .gl-input__input {
    min-width: 100% !important;
    padding-left: 10px;
  }
  .user-guide__search-background {
    display: none;
  }
  .user-guide__content {
    grid-template-columns: repeat(1, 1fr);
    gap: 14px;
    padding: 16px;
    border-radius: 8px 8px 0 0;
  }
  .user-guide__pagination {
    margin: 0 -8px;
    padding: 10px 16px;
    border-radius: 0;
  }
  .user-guide__pagination .pp-pagination-table {
    flex-direction: row;
    row-gap: 0;
  }
}


.loading-fade-enter-active,
.loading-fade-leave-active {
  transition: opacity 0.2s ease;
}
.loading-fade-enter,
.loading-fade-leave-to {
  opacity: 0;
}
</style>