<template>
  <a
    class="user-guide__card"
    :href="card.fileLink"
    target="_blank"
  >
    <div class="user-guide__card-icon">
      <gl-icon
        :height="20"
        name="ug-open-link"
        :width="20"
      />
    </div>
    <div
      class="user-guide__card-title"
      v-html="card.name"
    />
    <div class="user-guide__card-description">
      {{ card.description }}
    </div>
  </a>
</template>

<script>
import GlIcon from '@/components/gl-icon'

export default {
  components: {
    GlIcon,
  },
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  }
}
</script>

<style scoped>
.user-guide__card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 40px 32px;
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 12px;
  text-decoration: none;
  position: relative;
  transition: background-color 0.4s ease, border 0.4s ease;
}
.user-guide__card-icon {
  opacity: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 20px;
  transform: translate(-5px, 5px) scale(0.8);
  transition: transform 0.4s ease, opacity 0.4s ease;
}
.user-guide__card-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: var(--space-cadet);
  transition: color 0.4s ease;
}
.user-guide__card-title::v-deep span {
  background-color: var(--color-tag-50);
}
.user-guide__card-description {
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  -webkit-line-clamp: 2; */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-grey-6-e);
  /* overflow: hidden;
  text-overflow: ellipsis; */
}
.user-guide__card:hover {
  background-color: var(--Accent-GL-Background);
  border: 1px solid var(--accent-reflex-blue);
}
.user-guide__card:hover .user-guide__card-icon {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}
.user-guide__card:hover .user-guide__card-title {
  color: var(--accent-reflex-blue);
}
</style>